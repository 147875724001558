import {
  ExploreCellDataframe,
  SemanticDatasetInput,
} from "../sql/dataSourceTableConfig.js";
import { concatRespectingCase } from "../utils/stringUtils.js";

import {
  COUNT_STAR_ARG,
  COUNT_STAR_LABEL,
  generateColumnIdForField,
} from "./exploreFieldUtils.js";
import { hqlAggToPivotAgg } from "./explorePivotUtils.js";
import { ExploreField, ExploreSpec } from "./types.js";

export function exploreTableTemporaryFieldName(field: ExploreField): string {
  let value = generateColumnIdForField(field);
  if (field.value === COUNT_STAR_ARG) {
    return COUNT_STAR_LABEL;
  }
  if (field.aggregation != null) {
    value = concatRespectingCase(
      field.value,
      // use pivot agg to stay in sync with the pivot column name
      `_${hqlAggToPivotAgg(field.aggregation)}`,
      "append",
    );
  }
  if (field.truncUnit != null) {
    value = concatRespectingCase(field.value, `_${field.truncUnit}`, "append");
  }
  return value;
}

export function exploreNestedTableEnabled({
  dataframe,
  enabledFeatureFlag,
  spec,
}: {
  spec: ExploreSpec;
  dataframe: ExploreCellDataframe | null | undefined;
  enabledFeatureFlag: boolean;
}): boolean {
  if (!enabledFeatureFlag) {
    return false;
  } else if (SemanticDatasetInput.guard(dataframe)) {
    return false;
  } else if (spec.joins != null && spec.joins.length > 0) {
    // joins not supported
    return false;
  } else if (spec.chartConfig.series.length > 1) {
    // multi-series not supported
    return false;
  } else if (spec.visualizationType === "pivot-table") {
    // no gotchas in pivot table right now, should work
    return true;
  } else if (spec.chartConfig.series[0]?.type === "histogram") {
    // cant bin
    return false;
  }
  return true;
}
